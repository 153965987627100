import React from 'react';
import { Link } from "gatsby";
import "../scss/contact.scss";

const thanks = () => (

  <div className="thanks__page"> 
    <h2>Thank You for getting in touch with me!!</h2>
    <p>I'll be in contact soon.</p>

    <Link to="/">Go back to home</Link>
  </div>
)
  
export default thanks;
